<template>
  <div class="main-container">
    <OrderStep :current="2"></OrderStep>
    <div class="order-detail">
      <div class="title">提交订单成功</div>
      <div class="detail">提交订单成功，请您尽快支付；订单号：{{order.order_number}} 应付金额：{{order.order_price|price}}</div>
    </div>
    <div class="pay">
      <div class="title">选择支付方式</div>
      <div class="pay_type">
        <span v-for="(item,index) in pay_types"
              @click="change_type(item.type,item.type=='balance')"
              :key="index"
              :class="item.type==type?'active':''">
          <img class="img"
               :src="item.src">
        </span>
      </div>
      <div class="codeUrl">
        <vue-qr v-if="codeUrl"
                :text="codeUrl"
                :size="135"
                :margin="0"></vue-qr>
        <i v-if="loading"
           class="el-icon-loading"></i>
      </div>
      <div class="acccount"
           v-if="type=='balance' && balance">
        <p>可用余额<span style="color:#B7383F">￥{{balance | toFixPrice}}</span></p>
        <el-button type="primary"
                   @click="change_type('balance',false)">余额支付</el-button>
      </div>
      <!-- <div>
        <el-radio-group v-model="payType" @change="payChange">
          <el-radio label="wx">
            微信支付
            <img
              src="@/assets/images/order/wxpay@3x.png"
              style="vertical-align: middle;width:27px;"
            />
          </el-radio>
          <el-radio label="ali">
            支付宝
            <img
              src="@/assets/images/order/alipay@3x.png"
              style="vertical-align: middle;width:22px;"
            />
          </el-radio>
        </el-radio-group>
      </div> -->
      <!-- <div class="er-img">
        <vue-qr v-if="codeUrl"
                :text="codeUrl"
                :size="105"
                :margin="0"></vue-qr>
        <i v-if="loading"
           class="el-icon-loading"></i>
      </div> -->
    </div>
  </div>
</template>
<script>
import ali_icon from '../../images/ali_pay@2x.png';
import wx_icon from '../../images/wx_pay@2x.png';
import account_icon from '../../images/account_pay@2x.png';
import OrderStep from "../../components/OrderStep.vue";
import VueQr from "vue-qr";
let timer;

export default {
  name: "OrderPay",
  components: {
    OrderStep,
    VueQr
  },
  data () {
    return {
      id: "",
      pay_types: [
        { src: ali_icon, type: 'ali' },
        { src: wx_icon, type: 'wx' },
        { src: account_icon, type: 'balance' }
      ],
      order: {},
      payType: "",
      codeUrl: "",
      loading: false,
      type: 'ali',
      balance: 0
    };
  },
  async created () {
    this.id = this.$route.params.id;
    this.$http.post("/api/app/myOrder/detail", {
      order_id: this.id
    }).then(res => {
      this.order = res;
      this.change_type('ali');
    });
    if (this.order.order_status == 2) {
      this.$router.push({
        name: "OrderSuccess",
        params: { id: this.order.id }
      });
    }
    this.get_balance();
  },
  methods: {
    get_balance () {
      this.$http.get('/api/app/balanceCenter').then(data => {
        this.balance = data.balance ? data.balance : '0';
      })
    },
    async payChange () {
      if (timer) {
        clearInterval(timer);
      }
      this.codeUrl = "";
      this.loading = true;
      let data = await this.$http.post("/api/app/pay", {
        type: this.payType,
        order_number: this.order.order_number
      });
      this.codeUrl = data.response.code_url;
      this.loading = false;
      timer = setInterval(async () => {
        let data = await this.$http.post(
          "/api/app/myOrder/detail",
          {
            order_id: this.id
          },
          { showLoading: false, isAuthRequest: true }
        );
        if (data.order_status == 2 || data.pay_status == 2) {
          clearInterval(timer);
          this.$router.push({
            name: "OrderSuccess",
            params: { id: this.id },
            query: { type: this.$route.query.type }
          });
        }
      }, 1000);
    },
    set_timer () {
      timer = setInterval(async () => {
        let data = await this.$http.post(
          "/api/app/myOrder/detail",
          {
            order_id: this.id
          },
          { showLoading: false, isAuthRequest: true }
        );
        if (data.order_status == 2 || data.pay_status == 2) {
          clearInterval(timer);
          this.$router.push({
            name: "OrderSuccess",
            params: { id: this.id },
            query: { type: this.$route.query.type }
          });
        }
      }, 1000);
    },
    change_type (type, balance) {
      if (timer) {
        clearInterval(timer);
      }
      this.type = type;
      this.codeUrl = '';
      if (this.order.order_number) {
        if (this.type != 'balance' || !balance) {
          this.$http.post("/api/app/pay", {
            type: this.type,
            order_number: this.order.order_number
          }).then(res => {
            this.codeUrl = res.response.code_url;
            this.order_id = res.response.order_id;
            this.set_timer();
          });
        } else {
          this.set_timer();
        }
      }
    }
  },
  beforeDestroy () {
    if (timer) {
      clearInterval(timer);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";

.main-container {
  padding-top: 30px;
}

.order-detail {
  background-color: white;
  padding: 16px 32px;
  margin-bottom: 30px;
  .title {
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 20px;
  }
  .detail {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }
}

.pay {
  background-color: white;
  padding: 16px 32px;
  margin-bottom: 30px;
  position: relative;
  .title {
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    padding-bottom: 20px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 16px;
  }
  .content {
    border: 1px solid #f7f6fb;
    margin: 5px;
    border-radius: 5px 5px 0px 0px;
    padding-bottom: 172px;
  }
  .title {
    height: 67px;
    line-height: 60px;
    padding-left: 19px;
  }
  .pay_type_change {
    height: 50px;
    line-height: 50px;
    color: #333333;
    background-color: #f9f9f9;
    line-height: 50px;
    padding-left: 12px;
    border-radius: 5px 5px 0px 0px;
  }
  .pay_type {
    margin: 30px 0 0 21px;
    span {
      display: inline-block;
      width: 150px;
      height: 50px;
      line-height: 50px;
      margin-right: 40px;
      text-align: center;
      border: 2px solid #eef1f2;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      &.active {
        border: 2px solid $primary-color;
      }
      &.active::after {
        content: "";
        position: absolute;
        right: -3px;
        bottom: -6px;
        width: 16px;
        height: 9px;
        background-color: $primary-color;
        transform: rotate(120deg);
        -ms-transform: rotate(120deg); /* IE 9 */
        -moz-transform: rotate(120deg); /* Firefox */
        -webkit-transform: rotate(150deg); /* Safari 和 Chrome */
        -o-transform: rotate(90deg);
      }
    }
    .img {
      width: 78px;
      height: 24px;
      vertical-align: middle;
    }
    span:nth-child(2) .img {
      width: 65px;
    }
    span:nth-child(3) .img {
      width: 91px;
      height: 22px;
    }
  }
  .codeUrl {
    text-align: center;
    margin-top: 94px;
    min-height: 80px;
  }
  .acccount {
    position: absolute;
    bottom: 30px;
    text-align: center;
    right: 36px;
    p {
      margin-bottom: 20px;
      font-size: 18px;
    }
    button {
      width: 148px;
    }
  }
}

.er-img {
  text-align: center;
}
</style>
