<template>
  <div class="main-container">
    <OrderStep></OrderStep>
    <div class="address-wrap" v-if="need_address || type == 12">
      <div class="order-title">确认收货地址</div>
      <div
        class="clear"
        style="overflow: hidden"
        :style="addressExpand ? 'height:auto;' : 'height:155px;'"
      >
        <el-card
          class="address"
          shadow="hover"
          v-for="address in addressList"
          :key="address.id"
          @click.native="chooseAddress(address.id, address.province_id)"
        >
          <div
            class="title"
            :title="
              address.province_name +
              address.city_name +
              '（' +
              address.user_name +
              '收）'
            "
          >
            {{
              address.province_name +
              address.city_name +
              "（" +
              address.user_name +
              "收）"
            }}
          </div>
          <div class="detail">
            {{
              address.province_name +
              " " +
              address.city_name +
              " " +
              address.district_name +
              " " +
              address.address
            }}
          </div>
          <img
            class="default"
            src="../../images/book/address-default@2x.png"
            v-if="address.is_default == 1"
          />
          <img
            class="ok"
            src="../../images/book/address-ok@2x.png"
            v-if="address.id == address_id"
          />
        </el-card>
      </div>
      <div class="clear">
        <span
          class="f-left expand"
          v-if="!addressExpand"
          @click="addressExpand = 1"
          >查看全部</span
        >
        <span class="f-left collapse" v-else @click="addressExpand = 0"
          >收起</span
        >
        <span class="f-right add" @click="addAddress">新增收货地址</span>
      </div>
    </div>
    <div class="shop-detail">
      <div class="order-title">商品信息</div>
      <table cellspacing="0">
        <thead>
          <tr>
            <th class="name">商品名称</th>
            <th class="num" v-if="type == 12">购买数量</th>
            <th class="price">商品价格</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="name">{{ shop.title }}</td>
            <td class="num" v-if="type == 12">{{ num }}</td>
            <td class="price">
              <Currency></Currency>
              <span>{{ shop.price | price }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- 赠送商品 -->
    <div class="gift-detail" v-if="gifts.length > 0">
      <div class="order-title">赠送商品</div>
      <table cellspacing="0">
        <thead>
          <tr>
            <th class="name">商品名称</th>
            <th class="num">类型</th>
            <th class="price">价格</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in gifts" :key="index">
            <td class="name">{{ item.gift_name }}</td>
            <td class="num">{{ getGiftType(item.gift_type) }}</td>
            <td class="price">
              <Currency></Currency>
              <span>{{ item.price | price }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- 备注信息(只有可能需要) -->
    <div class="remark-detail" v-if="type < 11 || type == 12">
      <div class="order-title">
        备注信息
        <span v-if="type < 11">
          （请输入您的联系方式方便我们将您加入售后群处理您的学习疑问和售后问题）
        </span>
      </div>
      <el-form label-width="100px" class="demo-ruleForm">
        <el-form-item v-if="type < 11" class="QQ" label="微信号">
          <el-input
            style="width: 300px"
            v-model="weixin"
            placeholder="请输入微信号"
          />
        </el-form-item>
        <el-form-item v-if="type < 11" class="QQ" label="推荐人">
          <el-input
            style="width: 300px"
            v-model="referrer"
            placeholder="请输入推荐人,如无推荐人请填无"
          />
        </el-form-item>
        <el-form-item v-if="type < 11" label="QQ">
          <el-input style="width: 300px" v-model="qq" placeholder="请输入QQ" />
        </el-form-item>

        <el-form-item label="留言">
          <el-input
            style="width: 737px"
            v-model="user_remark"
            :autosize="{ minRows: 4, maxRows: 8 }"
            type="textarea"
            maxlength="200"
            show-word-limit
            placeholder="请输入留言内容"
          />
        </el-form-item>
      </el-form>
    </div>
    <div class="balance-detail">
      <div class="order-title">结算信息</div>
      <div class="item-wrap">
        <div class="item">
          <div class="label ship-price" v-if="need_address || type == 12">
            <div class="empty"></div>
            <span>运费：</span>
          </div>
          <div class="label coupon">
            <div class="empty"></div>
            <span class="label">优惠券：</span>
          </div>
          <div class="label price">
            <div class="empty"></div>
            <span class="label">应付金额：</span>
          </div>
        </div>
        <div class="item">
          <div class="ship-price" v-if="need_address || type == 12">
            <span
              class="rmb"
              :class="{ 'coupon-none': couponList.length == 0 }"
            >
              <Currency class="currency-mini"></Currency>
              <span>{{ shop.ship_price | price }}</span>
            </span>
          </div>
          <div class="coupon">
            <span :class="{ 'coupon-none': couponList.length == 0 }">
              <span v-if="couponList.length == 0">暂无可用</span>
              <span v-else-if="couponList.length == 1">{{ coupon.label }}</span>
              <el-select
                v-else
                v-model="couponId"
                placeholder="请选择"
                class="coupon-select"
                @change="couponChange"
                style="width: 140px"
              >
                <el-option
                  v-for="item in couponList"
                  :key="item.coupon_id"
                  :label="item.label"
                  :value="item.coupon_id"
                ></el-option>
              </el-select>
            </span>
          </div>
          <div class="price">
            <span
              class="rmb"
              :class="{ 'coupon-none': couponList.length == 0 }"
            >
              <Currency class="currency-mini"></Currency>
              <span>{{ pay_price | price }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="clear contract">
        <div class="f-right">
          <el-checkbox v-model="acceptContract" class="f-left"></el-checkbox>
          <span>
            <span>我同意</span>
            <a
              href="javascript:void(0)"
              @click="openContract('order_contract')"
            >
              培训协议
            </a>
          </span>
        </div>
      </div>
      <div class="clear">
        <div class="submit f-right" @click="submitOrder">提交订单</div>
      </div>
    </div>
    <el-dialog
      class="address-form"
      title="创建地址"
      :center="true"
      :visible.sync="showAddressModal"
      width="650px"
    >
      <el-form
        :model="addressForm"
        :rules="addressRules"
        ref="addressForm"
        label-width="100px"
      >
        <el-form-item label="地址信息" prop="district_id">
          <AreaSelect
            :provinceId="addressForm.province_id"
            :cityId="addressForm.city_id"
            :districtId="addressForm.district_id"
            @change="areaChange"
          ></AreaSelect>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input
            v-model="addressForm.address"
            placeholder="请输入详细地址，如道路，门牌号，校区，楼栋数，单元等"
            type="textarea"
            :rows="3"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮政编码" prop="zip_code">
          <el-input
            v-model="addressForm.zip_code"
            placeholder="请输入邮编"
            type="text"
          ></el-input>
        </el-form-item>
        <el-form-item label="收货人姓名" prop="user_name">
          <el-input
            v-model="addressForm.user_name"
            placeholder="长度不超过25个字符"
            type="text"
            :maxlength="25"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input
            v-model="addressForm.mobile"
            placeholder="请输入手机号"
            type="text"
            :maxlength="11"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer clear">
        <el-checkbox class="f-left" v-model="addressForm.is_default">
          设为默认地址
        </el-checkbox>
        <el-button
          class="f-right"
          type="primary"
          size="small"
          @click="confirmAddress"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import AreaSelect from "@/components/AreaSelect.vue";
import OrderStep from "../../components/OrderStep.vue";
import { ShopType } from "@/utils/enums";
export default {
  name: "OrderConfirm",
  components: {
    OrderStep,
    AreaSelect,
  },
  data() {
    return {
      acceptContract: false,
      id: this.$route.params.id,
      type: this.$route.query.type,
      num: this.$route.query.num,
      shop: {},
      couponList: [],
      coupon: {},
      couponId: 0,
      addressExpand: 0,
      showAddressModal: false,
      addressForm: {
        province_id: "",
        city_id: "",
        district_id: "",
        address: "",
        zip_code: "",
        user_name: "",
        mobile: "",
        is_default: false,
      },
      addressRules: {
        district_id: [
          { required: true, message: "地址信息不能为空", trigger: "chnage" },
        ],
        address: [
          { required: true, message: "详细地址不能为空", trigger: "blur" },
        ],
        user_name: [
          { required: true, message: "收货人姓名不能为空", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "手机号码不能为空", trigger: "blur" },
        ],
      },
      addressList: [],
      address_id: "",
      province_id: 0,
      user_remark: "",
      qq: "",
      weixin: "",
      referrer: "",
      gifts: [],
      need_address: false,
    };
  },
  computed: {
    pay_price() {
      return this.shop.order_price;
    },
  },
  async created() {
    this.qq = this.$store.state.user_info.qq || "";
    await this.getShopInfo();
    if (this.type != 14) {
      this.couponList = await this.$http.post("/api/app/userCouponWithGoods", {
        shop_id: this.id,
        type: this.type,
      });
      this.couponList.forEach((c) => {
        c.label =
          "满" + c.full_reduction / 100 + "减" + c.discounted_price / 100;
      });
      if (this.couponList.length > 0) {
        this.couponList.unshift({
          id: 0,
          coupon_id: "",
          label: "不使用优惠券",
          discounted_price: 0,
        });
      }
      if (this.couponList.length == 1) {
        this.coupon = this.couponList[0];
        this.couponId = this.coupon.coupon_id;
      } else if (this.couponList.length > 1) {
        let coupon = this.couponList.filter(
          (c) => c.coupon_id == this.shop.checked_coupon.coupon_id
        )[0];
        if (coupon) {
          this.coupon = coupon;
          this.couponId = coupon.coupon_id;
        }
      }
    }
    if (this.type == 12 || this.need_address) {
      await this.getAddress();
    }
  },
  methods: {
    openContract(key) {
      const { href } = this.$router.resolve({
        name: "Contract",
        query: {
          key: key,
        },
      });
      window.open(href);
    },
    //获取订单信息
    async getShopInfo() {
      let params = { shop_id: this.id, type: this.type };
      if (this.type == 12) {
        params.product_number = this.num;
      }
      params.province_id = this.province_id;
      this.gifts = [];
      let data = await this.$http.post("/api/app/order/shopInfo", params);
      if (data.child && data.child.length > 0) {
        data.child.forEach((item) => {
          this.gifts = this.gifts.concat(item.list);
        });
      }
      this.shop = data.info;
      this.need_address = data.need_address ? data.need_address : 0;
    },

    async submitOrder() {
      if (!this.acceptContract) {
        this.$message.warning("请先同意培训协议");
        return;
      }
      let params = { shop_id: this.id, type: this.type };
      if (this.type < 11) {
        //课程需要填写备注信息
        params.qq = this.qq;
        params.user_remark = this.user_remark;

        if (!this.weixin) {
          this.$message.warning("请先填写微信信息");
          return false;
        } else {
          params.weixin = this.weixin;
          params.user_remark = this.user_remark;
        }

        if (!this.referrer) {
          this.$message.warning("请先填写推荐人信息");
          return false;
        } else {
          params.referrer = this.referrer;
          params.user_remark = this.user_remark;
        }
      }
      if (this.couponId) {
        params.user_coupon_id = this.couponId;
      }
      if (this.type == 12 || this.address_id) {
        params.address_id = this.address_id;
        params.product_number = this.num;
        params.user_remark = this.user_remark;
      }
      let data = await this.$http.post("/api/app/order/downOrder", params);
      if (data.code == 203) {
        data = JSON.parse(data.msg);
        await this.$warning(data.msg);
        this.$router.push({
          name: "OrderPay",
          params: { id: data.data.order_id },
        });
      } else {
        if (data.pay_status == 2) {
          this.$router.push({
            name: "OrderSuccess",
            params: { id: data.id },
            query: { type: this.$route.query.type },
          });
        } else {
          this.$router.push({
            name: "OrderPay",
            params: { id: data.id },
            query: { type: this.$route.query.type },
          });
        }
      }
    },
    /**
     * 赠品类型
     */
    getGiftType(val) {
      return ShopType.getValue(val);
    },
    async couponChange() {
      if (this.couponId) {
        let data = await this.$http.post("/api/app/order/selectCoupon", {
          shop_id: this.id,
          coupon_id: this.couponId,
          product_number: this.num,
        });
        this.shop.order_price = data.order_price;
        this.coupon = this.couponList.filter(
          (c) => c.coupon_id == this.couponId
        )[0];
      } else {
        this.shop.order_price = this.shop.price;
      }
    },
    areaChange(area) {
      this.addressForm.province_id = area.provinceId;
      this.addressForm.city_id = area.cityId;
      this.addressForm.district_id = area.districtId;
    },
    async getAddress() {
      this.addressList = await this.$http.get("/api/app/address");
      let address = this.addressList.filter((a) => a.is_default == 1)[0];
      if (address) {
        this.address_id = address.id;
        this.province_id = address.province_id;
        this.getShopInfo();
      }
    },
    //选中地址
    chooseAddress(id, province_id) {
      this.address_id = id;
      this.province_id = province_id;
      this.getShopInfo();
    },
    addAddress() {
      this.showAddressModal = true;
      this.addressForm.province_id = "";
      this.addressForm.city_id = "";
      this.addressForm.district_id = "";
      this.addressForm.address = "";
      this.addressForm.zip_code = "";
      this.addressForm.user_name = "";
      this.addressForm.mobile = "";
    },
    confirmAddress() {
      this.$refs["addressForm"].validate(async (valid) => {
        if (!valid) {
          return;
        }
        await this.$http.post("/api/app/address", {
          province_id: this.addressForm.province_id,
          city_id: this.addressForm.city_id,
          district_id: this.addressForm.district_id,
          address: this.addressForm.address,
          zip_code: this.addressForm.zip_code,
          user_name: this.addressForm.user_name,
          mobile: this.addressForm.mobile,
          is_default: this.addressForm.is_default ? 1 : 2,
        });
        this.showAddressModal = false;
        await this.getAddress();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";

.main-container {
  padding-top: 30px;
}
.shop-detail,
.remark-detail,
.gift-detail {
  background-color: white;
  margin-top: 30px;
  padding: 0 32px;
}
.remark-detail {
  .demo-ruleForm {
    padding: 21px 0;
  }
  /deep/ {
    .QQ .el-form-item__label::before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
}
.order-title {
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  padding: 16px 0;
  border-bottom: 1px solid #e9e9e9;
  span {
    font-size: 14px;
    color: #999999;
    font-family: PingFang SC;
    font-weight: 400;
  }
}
table {
  width: 100%;
  table-layout: fixed;
  margin-top: 24px;
  margin-bottom: 30px;
  th {
    color: rgba(0, 0, 0, 0.65);
    background-color: #fafafa;
    padding: 16px 0;
  }
  .name {
    text-align: left;
    padding-left: 106px;
  }
  .price,
  .num {
    width: 220px;
    text-align: center;
    padding-top: 16px;
  }
  td {
    padding: 16px 0;
    &.price {
      color: #ee1f1f;
      img {
        height: 16px;
      }
      img,
      span {
        vertical-align: middle;
      }
    }
  }
}
.balance-detail {
  background-color: white;
  margin-top: 30px;
  padding: 0 32px;
  margin-bottom: 50px;
  .item-wrap {
    display: flex;
    .item {
      display: flex;
      flex-direction: column;
      &:first-child {
        flex-grow: 1;
      }
      .ship-price {
        padding-top: 16px;
      }
      .price {
        padding-top: 16px;
      }
      .label {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(89, 89, 89, 1);
        text-align: right;
        flex-grow: 1;
        display: flex;
        align-items: center;
        .empty {
          flex-grow: 1;
        }
        span {
          display: inline-block;
          width: 70px !important;
          text-align: left !important;
          flex-grow: 0;
        }
      }
      span {
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}
.coupon {
  text-align: right;
  padding-top: 16px;
  font-size: 14px;
  .red {
    color: red;
    margin-right: 20px;
  }
}
.ship-price {
  text-align: right;
  .rmb {
    height: 14px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(238, 31, 31, 1);
    line-height: 14px;
    display: inline-block;
    img {
      height: 14px;
      vertical-align: middle;
      max-width: 14px;
    }
    span {
      vertical-align: middle;
    }
  }
}
.price {
  text-align: right;
  padding-top: 5px;
  padding-bottom: 16px;
  .rmb {
    height: 24px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(238, 31, 31, 1);
    line-height: 24px;
    display: inline-block;
    img {
      height: 24px;
      vertical-align: middle;
      max-width: 24px;
    }
    span {
      vertical-align: middle;
    }
  }
}
.submit {
  width: 90px;
  height: 40px;
  background: $primary_color;
  border-radius: 5px;
  font-size: 14px;
  font-family: PingFang SC;
  color: rgba(255, 255, 255, 1);
  line-height: 40px;
  text-align: center;
  margin-bottom: 16px;
  cursor: pointer;
}
.address-wrap {
  background-color: white;
  margin: 20px 0;
  padding: 20px 32px;
  .expand,
  .add {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(231, 77, 61, 1);
    padding: 6px 12px;
    background: rgba(231, 77, 61, 0.06);
    border-radius: 14px;
    cursor: pointer;
    margin-top: 24px;
  }
  .collapse {
    width: 80px;
    height: 28px;
    background: rgba(231, 77, 61, 1);
    border-radius: 14px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 28px;
    text-align: center;
    margin-top: 24px;
    cursor: pointer;
  }
}
.address-form {
  /deep/ {
    .el-dialog__body {
      padding: 25px 32px 0 32px;
    }
    .el-dialog__footer {
      padding: 0 32px 32px 32px;
    }
  }
}
.address {
  margin-top: 24px;
  float: left;
  width: 271px;
  height: 131px;
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  border: 1px solid rgba(217, 217, 217, 1);
  margin-right: 16px;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  &:nth-child(4n) {
    margin-right: 0;
  }
  .title {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(91, 96, 102, 1);
    line-height: 20px;
    margin-bottom: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .detail {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(140, 140, 140, 1);
    line-height: 22px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  .default {
    position: absolute;
    width: 67px;
    height: 28px;
    right: 0;
    top: 0;
  }
  .ok {
    position: absolute;
    width: 42px;
    height: 34px;
    right: 0;
    bottom: 0;
  }
}
.coupon-select {
  /deep/ {
    .el-input__inner {
      height: 26px;
      line-height: 26px;
    }
    .el-input__icon {
      line-height: 26px;
    }
  }
}

.contract {
  margin: 10px 0;
  color: #8c8c8c;
  a {
    color: $primary-color;
  }
  .el-checkbox {
    margin-right: 5px;
  }
}
</style>
