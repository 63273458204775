<template>
  <div class="main-container">
    <div class="success">
      <div>
        <img src="../../images/pay-success.png" />
      </div>
      <div class="title">支付成功</div>
      <div class="tip">您稍后可在”{{label}}“中查看购买的商品</div>
    </div>
    <div class="timer">
      <span>
        网页将于
        <span>{{second}}</span>
        s后跳转到“{{label}}”，
      </span>
      <router-link :to="{name:router,query:{type:$route.query.type}}">手动跳转</router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderSuccess",
  data() {
    return {
      second: 3,
      router: "",
      label: "",
      timer: 0
    };
  },
  created() {
    let type = this.$route.query.type;
    if (type == 1) {
      this.router = "MemberOto";
      this.label = "我的预约";
    } else if (type == 15) {
      this.router = "MemberExercise";
      this.label = "我的练习";
    } else if (type == 16) {
      this.router = "MemberExam";
      this.label = "我的考试";
    } else if (type != 14&&type != 12) {
      this.router = "MemberStudy";
      this.label = "我的学习";
    } else {
      this.router = "MemberOrder";
      this.label = "我的订单";
    }

    this.timer = setInterval(() => {
      let second = this.second;
      if (second == 0) {
        clearInterval(this.timer);
        this.$router.push({
          name: this.router,
          query: { type: this.$route.query.type }
        });
      } else {
        second--;
        this.second = second;
      }
    }, 1000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";

.success {
  background-color: white;
  text-align: center;
  margin-top: 30px;
  padding-top: 35px;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 24px;
    margin-bottom: 9px;
  }
  .tip {
    font-size: 14px;
    color: rgba(140, 140, 140, 1);
    padding-bottom: 46px;
  }
}

.timer {
  padding: 88px 0;
  text-align: center;
  /deep/ a {
    color: $primary-color;
  }
}
</style>
