<template>
  <div class="steps">
    <div class="step" :class="current==1?'current':''">
      <img src="@/assets/images/order/step-end@3x.png" v-if="current>1" style="width:32px;" />
      <span v-else class="number">1</span>
      <span>确认订单</span>
    </div>
    <div class="line"></div>
    <div class="step" :class="current==2?'current':''">
      <span class="number">2</span>
      <span>支付</span>
    </div>
    <div class="line"></div>
    <div class="step" :class="current==3?'current':''">
      <span class="number">3</span>
      <span>完成</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderStep",
  props: {
    current: {
      type: Number,
      default: 1
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";

.steps {
  background-color: white;
  padding: 44px 60px;
  display: flex;
  align-items: center;
  .step {
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    .number {
      display: inline-block;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      border: 1px solid #e3e3e3;
      text-align: center;
      line-height: 32px;
      color: #e3e3e3;
      margin-right: 8px;
    }
    img,
    span {
      vertical-align: middle;
    }
    img {
      margin-right: 8px;
    }
  }
  .current {
    color: #595959;
    .number {
      background-color: $primary-color;
      color: white;
    }
  }
  .line {
    width: 380px;
    height: 1px;
    background: rgba(0, 0, 0, 0.15);
    margin: 0 16px;
  }
}
</style>
